.loader {
    &-default {
        width: 40px;
        aspect-ratio: 1;
        color: $primary;
        position: relative;
        background: radial-gradient(10px, currentColor 94%, #0000);

        &::before {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 50%;
            background:
                radial-gradient(9px at bottom right, #0000 94%, currentColor) top left,
                radial-gradient(9px at bottom left, #0000 94%, currentColor) top right,
                radial-gradient(9px at top right, #0000 94%, currentColor) bottom left,
                radial-gradient(9px at top left, #0000 94%, currentColor) bottom right;
            background-size: 20px 20px;
            background-repeat: no-repeat;
            animation: l18 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
        }
    }

    &-heart {
        width: 20px;
        aspect-ratio: 1;
        color: #dc1818;
        background:
            radial-gradient(circle at 60% 65%, currentColor 62%, #0000 65%) top left,
            radial-gradient(circle at 40% 65%, currentColor 62%, #0000 65%) top right,
            linear-gradient(to bottom left, currentColor 42%, #0000 43%) bottom left,
            linear-gradient(to bottom right, currentColor 42%, #0000 43%) bottom right;
        background-size: 50% 50%;
        background-repeat: no-repeat;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: inherit;
            opacity: 0.4;
            animation: l3 1s infinite;
        }
    }

    &-star {
        width: 20px;
        aspect-ratio: 1;
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: #ffb940;
            box-shadow: 0 0 0 50px;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
        }

        &::after {
            animation: l5 1s infinite;
            transform: perspective(300px) translateZ(0px)
        }
    }
}

@keyframes l18 {
    33% {
        inset: -10px;
        transform: rotate(0deg)
    }

    66% {
        inset: -10px;
        transform: rotate(90deg)
    }

    100% {
        inset: 0;
        transform: rotate(90deg)
    }
}

@keyframes l3 {
    to {
        transform: scale(1.8);
        opacity: 0
    }
}

@keyframes l5 {
    to {
        transform: perspective(300px) translateZ(150px);
        opacity: 0
    }
}