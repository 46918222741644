.container__page.info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 50px;
  background: $home-wrapper-background-color;

  .page__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;

    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      padding: 10px;
      position: relative;
      @include stripeBackground;
      input {
        @include remove-previous-border-outline;
        color: $input-text-color;
        @include button-background-default;
        z-index: +2;
        padding: $input-padding;
        @include button-outline-default;

        &:-webkit-autofill {
          @include button-background-hover-default;
        }

        &:active, &:focus, &:target {
            color: white;
            outline: 1px solid $primary;
        }
      }
    }

    h1 {
      @include remove-previous-margin-padding;
      @extend .d-inline-block;
      z-index: +2;
      text-align: center;
      color: #9a8866;
      font-family: Bender;
      word-break: break-word;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 3em;
      padding: 50px 0;
      background: url("../../../assets/images/tarkov-official-site/h2_bg.png")
        no-repeat 50% scroll;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      background-size: contain;
      -o-background-size: contain;
    }

    ul {
      $p-1: 20px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 20px;
      gap: 20px;

      li {
        $p-2: 10px;
        $padding-calc: calc($p-1 + $p-2);
        min-width: 300px;
        width: calc(50% - $padding-calc);
        color: white;
        padding: 20px;
        border-radius: 5px;
        outline: 2px dashed $primary;
        background: rgb(15,15,15);
        @include stripeBackground;
        position: relative;
        z-index: 1;

        .details {
          position: relative;
          z-index: +2;

          .links {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;

            @media (max-width: 676px) {
              justify-content: center;
            }

            a {
              @extend .b-t-back,
                .t-d-none,
                .t-a-center,
                .c-p,
                .pos-r,
                .b-none,
                .f-s-1_5;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: +2;
              color: $grey-1;
              @include button-background-colored;
              @include tarkov-button-dimensions;
              @include button-colored-text-shadow;
              font-family: bender(bold);

              &:hover {
                @include button-background-colored-hover;
              }
            }
          }
        }
      }
    }
  }
}
