@font-face {
    font-family: 'Bender';
    src: url('../../fonts/Bender.eot');
    src: url('../../fonts/Bender.eot?#iefix') format('embedded-opentype'),
        /*url('fonts/Bender.woff2')       format('woff2'),*/
        url('../../fonts/Bender.woff') format('woff'),
        url('../../fonts/Bender.ttf') format('truetype'),
        url('../../fonts/Bender.svg#benderregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bender-Light';
    src: url('../../fonts/Bender-Light.eot');
    src: url('../../fonts/Bender-Light.eot?#iefix') format('embedded-opentype'),
        /*url('fonts/Bender-Light.woff2')              format('woff2'),*/
        url('../../fonts/Bender-Light.woff') format('woff'),
        url('../../fonts/Bender-Light.ttf') format('truetype'),
        url('../../fonts/Bender-Light.svg#benderlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bender-Bold';
    src: url('../../fonts/Bender-Bold.eot');
    src: url('../../fonts/Bender-Bold.eot?#iefix') format('embedded-opentype'),
        /*url('fonts/Bender-Bold.woff2')               format('woff2'),*/
        url('../../fonts/Bender-Bold.woff') format('woff'),
        url('../../fonts/Bender-Bold.ttf') format('truetype'),
        url('../../fonts/Bender-Bold.svg#benderbold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Neusa-DemiBold';
    src: url('../../fonts/Neusa-DemiBold.eot');
    src: url('../../fonts/Neusa-DemiBold.eot?#iefix') format('embedded-opentype'),
        /*url('fonts/Neusa-DemiBold.woff2')               format('woff2'),*/
        url('../../fonts/Neusa-DemiBold.woff') format('woff'),
        url('../../fonts/Neusa-DemiBold.ttf') format('truetype'),
        url('../../fonts/Neusa-DemiBold.svg#neusademibold') format('svg');
    font-weight: normal;
    font-style: normal;
}