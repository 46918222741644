@import "./classes";
@import "./vars";
@import "./mixins";
@import "./funcs";
@import "./fonts";

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  @include remove-previous-margin-padding;
  font-family: "Hanken Grotesk", sans-serif;
  font-family: bender;
}

body {
  @include remove-previous-margin-padding;
  overflow: hidden;
}

.container__page {
  @include remove-previous-margin-padding;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
}

.divider {
  @include remove-previous-margin-padding;
  width: 100%;
  padding: 0;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background: rgb(50, 50, 50);
    // background: rgb(35,35,35);
    position: absolute;
  }
}

.backButtonUni {
  @extend .b-t-back,
  .t-d-none,
  .t-a-center,
  .pos-r,
  .c-p,
  .m-b-20,
  .flex-center,
  .p-15,
  .w-fc,
  .b-none,
  .d-flex-i;
  z-index: +2;
  color: #363a3c;
  @include button-background-colored;
  width: 198px;
  height: 63px;
  font-size: 1.1em;
  @include button-colored-text-shadow;
  font-family: bender(bold);
  height: 50px !important;
  z-index: +2;

  &:hover {
    @include button-background-colored-hover;
  }
}

a {
  color: $primary;
}

.login__google {
  width: 250px;
  background-size: 100%;
}

@import "./loader";
@import "../pages/home/home.scss";
@import "../pages/dashboard/dashboard.scss";
@import "../pages/auth/auth.scss";
@import "../pages/info/info.scss";

@media (max-width: 680px) {

  input,
  select,
  option {
    font-size: 16px;
  }
}