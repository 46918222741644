@mixin remove-previous-margin-padding {
    margin: 0;
    padding: 0;
}

@mixin remove-previous-border-outline {
    outline: none;
    outline: 0;
    border: none;
    border: 0;
}

@mixin top-left {
    left: 0;
    top: 0;
}

@mixin basic-shadow-gold {
    box-shadow: 0 0 10px 0 #9a8866;
}

@mixin basic-transition {
    transition: 100ms ease all;
}

@mixin w100p-h100p {
    width: 100%;
    height: 100%;
}

@mixin w100vw-h100vh {
    width: 100vw;
    height: 100vh;
}

@mixin button-background-default {
    background: $tarkov-button-background;
}

@mixin button-background-hover-default {
    background: $tarkov-button-background-hover;
}

@mixin button-outline-default {
    outline: $button-outline-default;
}

@mixin stripe-background {
    content: "";
    width: 100%;
    height: 100%;
    @include top-left;
    background: url("../../../assets/images/tarkov-official-site/stripes_rotated.png");
    background-size: 100px;
    @include remove-previous-margin-padding;
    z-index: +1;
    position: absolute;
}

@mixin stripeBackground {
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        @include top-left;
        background: url("../../assets/images/tarkov-official-site/stripes_rotated.png");
        background-size: 100px;
        @include remove-previous-margin-padding;
        z-index: +1;
        position: absolute;
        @include basic-transition;
    }
}

@mixin stripeBackgroundTitle {
    background: url("../../assets/images/tarkov-official-site/h2_bg.png") no-repeat 50% scroll;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
    -o-background-size: contain;
}

@mixin button-transition-default {
    transition: color 200ms, text-shadow 200ms, background 200ms, background-image 200ms;
}

@mixin button-background-colored {
    background: url("../../assets/images/tarkov-official-site/button_huge_popup.png") no-repeat center center scroll;
}

@mixin button-background-colored-hover {
    background: url("../../assets/images/tarkov-official-site/button_huge_popup_hover.png") no-repeat center center scroll;
}

@mixin button-colored-text-shadow {
    text-shadow: $button-colored-text-shadow;
}

@mixin center-element-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin gradient-background($start-color, $end-color) {
    background: linear-gradient($start-color, $end-color);
}

@mixin tarkov-button-dimensions {
    width: 198px;
    height: 63px;
}