.dashboard {
  @extend .d-grid, .p-20;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 0.5fr repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  gap: 20px;
  background: rgb(5, 5, 5);

  .backtohome {
    margin-bottom: 10vh;
  }

  .disabled {
    @extend .pe-none;
    opacity: 0.2;
  }

  .error-message {
    @extend .us-none, .pos-r;
    border-radius: 5px;
    // outline: 1px solid #662d2d;
    color: rgb(240, 218, 218);
    background: rgba(240, 77, 77, 0.2);
    box-shadow: 0 0 10px 0 rgba(240, 77, 77, 0.4);
    margin: 10px;
    padding: 10px;
    z-index: +1;
  }

  .backButtonUni {
    @extend .b-t-back,
    .t-d-none,
    .t-a-center,
    .pos-r,
    .c-p,
    .m-b-20,
    .flex-center,
    .p-15,
    .w-fc,
    .b-none,
    .d-flex-i;
    z-index: +2;
    color: #363a3c;
    @include button-background-colored;
    width: 198px;
    height: 63px;
    font-size: 1.1em;
    @include button-colored-text-shadow;
    font-family: bender(bold);
    height: 50px !important;
    z-index: +2;

    &:hover {
      @include button-background-colored-hover;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: +999;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: +1000;

    .modal-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      background: transparent;
      position: relative;
      border-radius: 10px;
      background: $home-wrapper-background-color;
      padding: 30px 20px;
      @include basic-shadow-gold;
      position: relative;

      .close {
        @include button-background-default;
        @include button-outline-default;
        @include button-transition-default;
        border: none;
        color: white;
        width: 100%;
        cursor: pointer;
        padding: 5px 20px;
        position: relative;
        margin-top: 20px;

        &:hover {
          @include button-background-hover-default;
        }

        &::before {
          content: "";
          width: 100%;
          height: 2px;
          top: -20px;
          left: 0;
          background: $grey-1;
          position: absolute;
        }
      }

      .dashboard__btn {
        @extend .b-t-back,
        .t-d-none,
        .d-block,
        .t-a-center,
        .c-p,
        .pos-r,
        .b-none,
        .f-s-1_5;
        z-index: +2;
        color: $grey-1;
        @include button-background-colored;
        @include tarkov-button-dimensions;
        @include button-colored-text-shadow;
        font-family: bender(bold);
        margin-bottom: 10px;

        &:hover {
          @include button-background-colored-hover;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

      input,
      .input,
      select {
        @extend .b-none, .br-5;
        color: white;
        @include button-background-default;
        z-index: +2;
        margin-bottom: 10px;
        padding: 10px 15px;
        @include button-outline-default;

        option {
          @extend .p-10;
          background-color: rgb(15, 15, 15);
          color: white;

          &:hover {
            background: $primary;
          }
        }
      }
    }
  }

  .dashboard__container {
    @extend .flex-center, .flex-column, .w-100-p, .h-auto, .pos-r, .br-5, .p-30;
    justify-content: flex-start;
    grid-area: 1 / 1 / 4 / 2;
    @include basic-shadow-gold;
    @include top-left;
    z-index: 1;
    background: $home-wrapper-background-color;
    color: white;

    @include stripeBackground;

    form {
      @extend .d-flex, .flex-a-center, .flex-j-start, .flex-column;

      .objective__container {
        @extend .pos-r, .m-b-20;

        .objective {
          @extend .w-fc, .b-none, .br-5, .pos-r;
          color: white;
          @include button-background-default;
          z-index: +2;
          padding: 10px 15px;
          @include button-outline-default;
        }
      }
    }

    .inputs__container {
      @extend .flex-center, .flex-column;
    }

    a {
      color: $primary;
      z-index: +2;
    }

    div {
      @extend .p-10;
      z-index: +2;
    }

    .name {
      @extend .f-s-2;
      z-index: +2;
    }

    .email {
      @extend .f-s-2;
      z-index: +2;
    }

    .dropdown__taskform {
      label {
        margin-right: 10px;
      }
    }

    input,
    .input,
    select {
      @extend .b-none, .br-5;
      color: white;
      @include button-background-default;
      z-index: +2;
      margin-bottom: 10px;
      padding: 10px 15px;
      @include button-outline-default;

      option {
        @extend .p-10;
        background-color: rgb(15, 15, 15);
        color: white;

        &:hover {
          background: $primary;
        }
      }
    }

    button {
      @extend .b-t-back,
      .t-d-none,
      .d-block,
      .t-a-center,
      .c-p,
      .pos-r,
      .b-none,
      .f-s-1_5;
      z-index: +2;
      color: $grey-1;
      @include button-background-colored;
      @include tarkov-button-dimensions;
      @include button-colored-text-shadow;
      font-family: bender(bold);

      &:hover {
        @include button-background-colored-hover;
      }
    }

    .level {
      @extend .br-5, .m-b-10;
      @include button-outline-default;
      @include button-background-default;

      span {
        color: $primary;
      }
    }

    .largeFont {
      font-size: 10em;
    }
  }

  .tarkov {
    @extend .w-100-p,
    .h-auto,
    .pos-r,
    .br-5;
    height: fit-content;
    padding: 0 30px;
    grid-area: 1 / 2 / 2 / 3;
    @include basic-shadow-gold;
    @include top-left;
    z-index: 1;
    background: $home-wrapper-background-color;
    color: white;

    @include stripeBackground;

    .time {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: relative;
      gap: 20px;
      z-index: +1;

      .txt {
        display: flex;

        img {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .el {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 5px;
        @include button-outline-default;
        background: rgba(35, 35, 35, 0.5);
      }

      @media (max-width: 560px) {
        .txt {
          display: none;
        }
      }
    }
  }

  .main {
    @extend .d-flex,
    .flex-a-start,
    .flex-j-start,
    .flex-column,
    .w-100-p,
    .h-auto,
    .pos-r,
    .br-5,
    .p-30;
    grid-area: 2 / 2 / 3 / 3;
    @include basic-shadow-gold;
    @include top-left;
    z-index: 1;
    background: $home-wrapper-background-color;
    color: white;

    @include stripeBackground;

    .traders {
      @extend .w-100-p, .flex-center, .flex-row, .flex-wrap, .g-20;
      gap: 20px;
      padding-top: 20px;
      z-index: +1;

      .trader {
        @extend .pos-r, .c-p;

        width: 100px;
        height: 100px;
        @include button-outline-default;
        outline-offset: 1px;
        margin-bottom: 40px;

        &.disabled {
          @extend .d-none;
        }

        img {
          @extend .w-100-p, .h-100-p;
        }

        .name {
          @extend .flex-center, .w-100-p, .pos-a, .left;
          color: $tarkov-gold-light;
          $height: 25px;
          height: $height;
          padding-bottom: 2.5px;
          bottom: calc(($height * -1) + (5px * -1));
          background: linear-gradient(180deg,
              rgba(35, 35, 35, 1) 0%,
              rgba(30, 30, 30, 1) 100%);
        }
      }
    }

    @media (max-width: 860px) {
      padding: 20px 10px 10px 10px;

      .traders {
        .trader {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .info {
    grid-area: 3 / 2 / 4 / 3;
    @extend .d-flex,
    .flex-a-start,
    .flex-j-center,
    .flex-row,
    .flex-wrap,
    .w-100-p,
    .h-auto,
    .pos-r,
    .br-5,
    .p-30;
    @include basic-shadow-gold;
    @include top-left;
    z-index: 1;
    background: $home-wrapper-background-color;
    color: white;

    @include stripeBackground;

    p {
      z-index: +2;
    }

    .info__item {
      @extend .br-5;
      max-width: 200px;
      margin: 10px;
      z-index: +2;
      padding: 20px;

      @include button-background-default;
      @include button-outline-default;
      @include basic-transition;

      h1 {
        @include remove-previous-margin-padding;
        margin-bottom: 10px;
        font-size: 1.2em;
      }

      .item__items {
        margin: 0;
        padding-left: 10px;

        li {
          div {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            background: rgba(25, 25, 25, 0.5);
            position: relative;
            @include stripeBackground;
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 5px;

            span {
              @extend .flex-center;
              z-index: +2;
            }

            .btn {
              color: white;
              @extend .t-d-none,
              .d-inline-block,
              .t-a-center,
              .c-p,
              .pos-r,
              .b-none,
              .f-s-1;
              margin: 10px;
              margin-right: 0;
              @include button-outline-default;
              @include button-background-default;
              @include button-transition-default;
              z-index: +2;

              &.add {
                color: #5EEB5B;
                outline: 1px solid #5EEB5B;

                &:hover {
                  background: #5EEB5B;
                  outline: 1px solid rgb(25, 25, 25);
                  color: rgb(25, 25, 25);
                }
              }

              &.minus {
                color: #eb2323;
                outline: 1px solid #eb2323;

                &:hover {
                  background: #eb2323;
                  outline: 1px solid rgb(25, 25, 25);
                  color: rgb(25, 25, 25);
                }
              }
            }
          }
        }
      }

      .complete {
        $active-color: #5EEB5B;
        $active-text-color: rgb(25, 25, 25);
        $active-outline-color: $active-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $active-color;
        width: fit-content;
        height: fit-content;
        border: none;
        padding: 5px;
        margin: 10px;
        margin-top: 20px;
        outline: 1px solid transparent;
        border-radius: 5px;
        outline: 1px solid $active-color;
        background: rgb(25, 25, 25);
        cursor: pointer;
        @include basic-transition;

        &:hover {
          color: rgb(25, 25, 25);
          background: $active-color;
          outline: 1px solid $active-outline-color;

          .icon {
            color: $active-text-color;
          }
        }

        .icon {
          @include remove-previous-margin-padding;
        }

        &.copy {
          color: rgb(150, 150, 150);
          outline: 1px solid rgb(150, 150, 150);

          &:hover {
            outline: 1px solid rgb(150, 150, 150);
            background: rgb(150, 150, 150);
          }
        }
      }
    }
  }

  @media (max-width: 860px) {
    grid-column-gap: 20px;
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.5fr repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 20px;

    .dashboard__container {
      height: fit-content;
      grid-area: 1 / 1 / 2 / 2 !important;
    }

    .tarkov {
      grid-area: 2 / 1 / 3 / 2 !important;
    }

    .main {
      grid-area: 3 / 1 / 4 / 2 !important;
    }

    .info {
      grid-area: 4 / 1 / 5 / 2 !important;
    }

    .backtohome {
      margin: 0;
    }

    .dashboard__container.before {
      padding: 0;
      display: flex;
      flex-direction: row;
      gap: 20px;

      .head-button {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 50px !important;
        height: 50px !important;

        img {
          width: 30px;
        }
      }
    }

    .dashboard__container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      grid-area: auto;
      width: 100%;

      a {
        display: block;
        margin-bottom: 20px;
      }

      .level {
        display: inline-block;
      }

      input {
        display: inline-block;
        margin: 10px;
      }
    }

    .main {
      grid-area: auto;
      width: 100%;
    }
  }
}

.hidden {
  @extend .d-flex, .flex-row;
}

@media (max-width: 600px) {

  input,
  select {
    font-size: 16px !important;
  }
}

@import "_trader.scss";