.p- {
  &5 {
    padding: 5px !important;
  }

  &10 {
    padding: 10px !important;
  }

  &15 {
    padding: 15px !important;
  }

  &20 {
    padding: 20px !important;
  }

  &25 {
    padding: 25px !important;
  }

  &30 {
    padding: 30px !important;
  }

  &35 {
    padding: 35px !important;
  }

  &40 {
    padding: 40px !important;
  }
}

.g- {
  &5 {
    gap: 5px;
  }

  &10 {
    gap: 10px;
  }

  &15 {
    gap: 15px;
  }

  &20 {
    gap: 20px;
  }
}

@for $i from 1 through 20 {
  .br-#{$i} {
    border-radius: #{$i}px;
  }
}

.w- {
  &auto {
    width: auto;
  }

  &fc {
    width: fit-content;
  }
}

.w-100-p {
  width: 100% !important;
}

.w-100-vw {
  width: 100vw;
}

.h- {
  &auto {
    width: auto;
  }
}

.h-100-p {
  height: 100%;
}

.h-100-vh {
  height: 100vh;
}

.pe- {
  &none {
    pointer-events: none;
  }
}

.us- {
  &none {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.d- {
  &flex {
    display: flex;

    &-i {
      display: flex !important;
    }
  }

  &grid {
    display: grid;
  }

  &inline-block {
    display: inline-block;
  }

  &block {
    display: block;
  }

  &none {
    display: none;

    &-i {
      display: none !important;
    }
  }
}

.flex- {
  &row {
    flex-direction: row;
  }

  &column {
    flex-direction: column;
  }

  &wrap {
    flex-wrap: wrap;
  }

  &center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pos- {
  &r {
    position: relative;
  }

  &a {
    position: absolute;
  }

  &f {
    position: fixed;
  }

  &s {
    position: static;
  }
}

.flex-a- {
  &center {
    align-items: center;
  }

  &start {
    align-items: flex-start;
  }

  &end {
    align-items: flex-end;
  }
}

.flex-j- {
  &center {
    justify-content: center;
  }

  &start {
    justify-content: flex-start;
  }

  &end {
    justify-content: flex-end;
  }
}

.t- {
  &a- {
    &center {
      text-align: center;
    }

    &left {
      text-align: left;
    }

    &right {
      text-align: right;
    }
  }

  &d- {
    &none {
      text-decoration: none;
    }
  }
}

.tt- {
  &u {
    text-transform: uppercase;
  }
}

.b- {
  &none {
    border: none;
  }

  &t- {
    &default {
      transition:
        color 200ms,
        text-shadow 200ms,
        background 200ms;
    }

    &back {
      transition:
        color 200ms,
        text-shadow 200ms,
        background 200ms,
        background-image 200ms;
    }
  }
}

.c- {
  &p {
    cursor: pointer;
  }
}

.m- {
  @for $i from 1 through 10 {
    $increment: $i * 5;

    &t-#{$increment} {
      margin-top: #{$increment}px !important;
    }
  }

  @for $i from 1 through 10 {
    $increment: $i * 5;

    &b-#{$increment} {
      margin-bottom: #{$increment}px;
    }
  }

  @for $i from 1 through 10 {
    $increment: $i * 5;

    &r-#{$increment} {
      margin-right: #{$increment}px;
    }
  }
}

.o- {
  &none {
    outline: none;
  }
}

.f {
  &-s {
    &-1 {
      font-size: 1em;
    }

    &-1_5 {
      font-size: 1.1em;
    }

    &-2 {
      font-size: 1.2em;
    }

    &-3 {
      font-size: 1.6em;
    }

    &-4 {
      font-size: 1.8em;
    }

    &-5 {
      font-size: 2em;
    }
  }
}

.left {
  left: 0;
}

.top {
  top: 0;
}

.right {
  right: 0;
}

.bottom {
  bottom: 0;
}

.bg- {
  &default {
    background: rgba(0, 0, 0, 0.9);
  }
}

.user-select-none {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.not-draggable {
  -webkit-user-drag: none;
}