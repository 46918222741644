$tarkov-gold: #9a8866;
$tarkov-gold-light: #dfd7ca;
$grey-1: #363a3c;

$tarkov-button-background: linear-gradient(180deg,
    rgba(5, 5, 5, 1) 0%,
    rgba(0, 0, 0, 1) 100%);
$tarkov-button-background-hover: linear-gradient(180deg,
    rgba(5, 5, 5, 1) 30%,
    rgba(62, 27, 27, 1) 100%);

$button-outline-default: 1px solid rgb(100, 100, 100);
$button-colored-text-shadow: 0.018cm 0.031cm 0 #ffffff;

$info-blue: #3590F3;

$primary: $tarkov-gold;

@import "../pages/home/vars";