.dashboard {
    &.subpage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        .subpage__wrapper {
            @include remove-previous-margin-padding;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include basic-shadow-gold;
            padding: 10px;
            width: 100%;
            height: auto;
            left: 0;
            top: 0;
            z-index: 1;
            position: relative;
            background: rgba(5, 5, 5, 0.9);
            border-radius: 5px;
            z-index: 1;
            color: white;

            @include stripeBackground;

            &.subpage__task-details {
                .task-details {
                    color: white;
                    padding: 20px;

                    .tab-navigation {
                        bottom: -15px;
                        left: 10px;
                        margin-top: 10px;
                        position: relative;

                        .tab-button {
                            height: fit-content;
                            width: fit-content;
                            border-radius: 5px 5px 0 0;
                            outline: 1px solid rgb(150, 150, 150);
                            border: none;
                            // border-bottom: 1px solid rgb(150,150,150);
                            opacity: 0.9;
                            padding: 10px;
                            top: 5px;
                            margin-right: 5px;
                            color: $primary;
                            background: rgb(10, 10, 10);
                            position: relative;
                            cursor: pointer;
                            filter: brightness(60%);
                            @include basic-transition;

                            &.active {
                                filter: brightness(100%);
                                opacity: 1 !important;
                                top: 0 !important;
                            }
                        }
                    }

                    h2 {
                        text-align: center;
                    }

                    .taskMessage {
                        border-radius: 5px;
                        outline: 1px solid rgb(150, 150, 150);
                        padding: 20px;
                        background: rgb(5, 5, 5);
                        position: relative;

                        @media (max-width:380px) {
                            &::after {
                                content: "";
                                display: none;
                            }
                        }
                    }

                    .taskObjectives {
                        .objectives__container {
                            display: flex;
                            flex-direction: column;

                            .objective {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                flex-direction: row;
                                outline: 1px solid rgb(150, 150, 150);
                                border-radius: 5px;
                                padding: 10px;
                                margin-bottom: 10px;
                                background: rgb(25, 25, 25);

                                .actions {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: row;
                                    gap: 10px;

                                    button {
                                        $active-color: #5EEB5B;
                                        $active-text-color: rgb(25, 25, 25);
                                        $active-outline-color: $active-color;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        color: $active-color;
                                        width: fit-content;
                                        height: fit-content;
                                        border: none;
                                        padding: 5px;
                                        outline: 1px solid transparent;
                                        border-radius: 5px;
                                        outline: 1px solid $active-color;
                                        background: rgb(25, 25, 25);
                                        cursor: pointer;
                                        @include basic-transition;

                                        &:hover {
                                            background: #FF595E;
                                            background: $active-color;
                                            outline: 1px solid $active-outline-color;

                                            .icon {
                                                color: $active-text-color;
                                            }
                                        }

                                        .icon {
                                            @include remove-previous-margin-padding;
                                        }

                                        &.copy {
                                            color: rgb(150, 150, 150);
                                            outline: 1px solid rgb(150, 150, 150);

                                            &:hover {
                                                outline: 1px solid rgb(150, 150, 150);
                                                background: rgb(150, 150, 150);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .rewards {
                        .rewards__container {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            flex-wrap: wrap;

                            .reward {
                                outline: 1px solid $primary;
                                padding: 10px;
                                margin: 10px;
                                border-radius: 5px;
                                background: rgb(15, 15, 15);
                                cursor: default;

                                &:hover {
                                    @include basic-shadow-gold;
                                }
                            }
                        }
                    }
                }
            }

            .taskSearch {
                color: white;
                background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
                z-index: +2;
                margin-bottom: 10px;
                padding: 10px 15px;
                border: none;
                outline: none;
                border-radius: 5px;
                outline: 1px solid rgb(100, 100, 100);

                &:hover {
                    background: linear-gradient(180deg, rgba(5, 5, 5, 1) 0%, rgba(0, 0, 0, 1) 100%);
                }

                option {
                    background-color: rgb(15, 15, 15);
                    padding: 10px;
                    color: white;

                    &:hover {
                        background: $primary;
                    }
                }
            }

            .wrapper__section {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: +2;
                height: fit-content;

                &.tasks {
                    width: 100%;
                    padding: 20px;
                    position: relative;

                    .tasks__task {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: color 200ms, text-shadow 200ms, background 200ms;
                        margin-bottom: 10px;
                        width: 100%;
                        color: white;
                        text-decoration: none;
                        height: auto;
                        padding: 10px 20px;
                        outline: 1px solid rgb(100, 100, 100);
                        border-radius: 5px;
                        background: linear-gradient(180deg,
                                rgba(5, 5, 5, 1) 0%,
                                rgba(0, 0, 0, 1) 100%);
                        position: relative;
                        @include basic-transition;

                        &:hover {
                            background: linear-gradient(180deg,
                                    rgba(10, 10, 10, 1) 30%,
                                    rgba(62, 27, 27, 1) 100%);
                        }

                        img {
                            @extend .user-select-none, .not-draggable;
                            pointer-events: none;

                            @media (max-width: 490px) {
                                display: none;
                            }
                        }

                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            width: 100%;
                            height: 100%;
                            color: white;
                            text-decoration: none;
                            position: relative;
                        }

                        button {
                            color: white;
                            width: 100px;
                            border: none;
                            outline: 1px solid rgb(150, 150, 150);
                            background: linear-gradient(180deg,
                                    rgba(5, 5, 5, 1) 0%,
                                    rgba(0, 0, 0, 1) 100%);
                            border-radius: 5px;
                            padding: 5px 20px;
                            cursor: pointer;

                            &:hover {
                                background: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}