@import "./vars";

.login {
  @extend .flex-center, .flex-column;
  background: $black-1;

  .login__container {
    @extend .d-flex,
    .flex-a-center,
    .flex-j-start,
    .flex-column,
    .h-auto,
    .pos-r,
    .br-5,
    .p-20;
    @include basic-shadow-gold;
    @include top-left;
    z-index: 1;
    background: $black-2;
    color: $text-color;

    @include stripeBackground;

    * {
      @extend .pos-r;
      z-index: +2;
    }

    h1 {
      @extend .t-a-center, .tt-u, .d-inline-block;
      color: $tarkov-gold-light;
      font-family: Bender;
      word-break: break-word;
      font-size: 3em;
      @include stripeBackgroundTitle;
    }

    input {
      @include remove-previous-border-outline;
      @extend .m-b-10, .br-5;
      color: $input-text-color;
      @include button-background-default;
      z-index: +2;
      padding: $input-padding;
      @include button-outline-default;

      &:-webkit-autofill {
        @include button-background-hover-default;
      }
    }

    button {
      @extend .t-d-none,
      .flex-center,
      .t-a-center,
      .f-s-1_5,
      .c-p,
      .pos-r,
      .b-none;
      z-index: +2;
      @include button-transition-default;
      color: $grey-1;
      @include button-background-colored;
      @include tarkov-button-dimensions;
      @include button-colored-text-shadow;
      font-family: bender(bold);

      &:hover {
        @include button-background-colored-hover;
      }

      &.login__google {
        width: $google-button-width;
        background-size: 100%;
      }

      img {
        width: 20px;
        margin-right: 7.5px;
        filter: drop-shadow($button-colored-text-shadow);
      }
    }

    div {
      @extend .m-t-10;
      color: $text-color;

      a {
        color: $primary;
      }
    }
  }
}

.register {
  @extend .flex-center, .flex-column;
  background: $black-1;

  .register__container {
    @extend .d-flex,
    .flex-a-center,
    .flex-j-start,
    .flex-column,
    .h-auto,
    .pos-r,
    .br-5,
    .p-30;
    @include basic-shadow-gold;
    @include top-left;
    z-index: 1;
    background: $home-wrapper-background-color;
    color: $text-color;

    @include stripeBackground;

    * {
      @extend .pos-r;
      z-index: +2;
    }

    h1 {
      @extend .t-a-center, .tt-u, .d-inline-block;
      color: $tarkov-gold-light;
      font-family: Bender;
      word-break: break-word;
      font-size: 3em;
      @include stripeBackgroundTitle;
    }

    input {
      @include remove-previous-border-outline;
      @extend .br-5, .m-b-10;
      color: $input-text-color;
      @include button-background-default;
      z-index: +2;
      padding: $input-padding;
      @include button-outline-default;

      &:-webkit-autofill {
        @include button-background-hover-default;
      }
    }

    button {
      @include remove-previous-border-outline;
      @extend .t-d-none, .flex-center, .t-a-center, .pos-r, .c-p, .f-s-1_5;
      z-index: +2;
      @include button-transition-default;
      color: $grey-1;
      @include button-background-colored;
      @include tarkov-button-dimensions;
      @include button-colored-text-shadow;
      font-family: bender(bold);

      &:hover {
        @include button-background-colored-hover;
      }

      &.register__google {
        width: $google-button-width;
        background-size: 100%;
      }

      img {
        width: 20px;
        margin-right: 7.5px;
        filter: drop-shadow($button-colored-text-shadow);
      }
    }

    div {
      @extend .m-t-10;
      color: $text-color;

      a {
        color: $primary;
      }
    }
  }
}

.reset {
  @extend .flex-center, .flex-column;
  background: $black-1;

  .reset__container {
    @extend .d-flex,
    .flex-a-center,
    .flex-j-start,
    .flex-column,
    .h-auto,
    .pos-r,
    .br-5,
    .p-30;
    @include basic-shadow-gold;
    @include top-left;
    z-index: 1;
    background: $home-wrapper-background-color;
    color: $text-color;

    @include stripeBackground;

    * {
      @extend .pos-r;
      z-index: +2;
    }

    h1 {
      @extend .t-a-center, .tt-u, .d-inline-block;
      color: $tarkov-gold-light;
      font-family: Bender;
      word-break: break-word;
      font-size: 3em;
      @include stripeBackgroundTitle;
    }

    input {
      @include remove-previous-border-outline;
      @extend .br-5, .m-b-10;
      color: $input-text-color;
      @include button-background-default;
      z-index: +2;
      padding: $input-padding;
      @include button-outline-default;

      &:-webkit-autofill {
        @include button-background-hover-default;
      }
    }

    button {
      @include remove-previous-border-outline;
      @extend .t-d-none, .flex-center, .t-a-center, .pos-r, .c-p, .f-s-1_5;
      z-index: +2;
      @include button-transition-default;
      color: $grey-1;
      @include button-background-colored;
      @include tarkov-button-dimensions;
      @include button-colored-text-shadow;
      font-family: bender(bold);

      &:hover {
        @include button-background-colored-hover;
      }

      img {
        width: 20px;
        margin-right: 7.5px;
        filter: drop-shadow($button-colored-text-shadow);
      }
    }

    div {
      @extend .m-t-10;
      color: $text-color;

      a {
        color: $primary;
      }
    }
  }
}